import { useEffect } from 'react';

import { smartVetrinaRedirect } from '../../../utils';

export const useAutoRedirect = (): void => {
  useEffect(() => {
    setTimeout(() => {
      smartVetrinaRedirect(false, false);
    }, 45000);
  }, []);
};
