import React from 'react';
import s from '../style/HelpInfo.module.scss';

export const HelpInfoTypeSecond = () => (
  <>
    <h3 className={s.info__subtitle}>Какие условия по займам на карту?</h3>
    <span className={s.info__text}>
      Микрокредитные компании предлагают небольшие суммы в долг. Средний заём для нового
      клиента выдается на следующих условиях:
    </span>
    <span className={s.info__text}>1) Сумма от 1 000 до 30 000 рублей;</span>
    <span className={s.info__text}>2) Срок от 1 до 31 дня;</span>
    <span className={s.info__text}>3) Ставка с минимальным процентом в день;</span>

    <span className={s.info__text}>
      Куда выгоднее и перспективнее становиться постоянным клиентом микрокредитной
      компании. Для таких, проверенных временем и погашенными кредитами заемщиков,
      предлагаются лучшие условия без отказа:
    </span>
    <span className={s.info__text}>1) Сумма до 50 000, а порой и 100 000 рублей;</span>
    <span className={s.info__text}>2) Ставка с минимальным процентом в день;</span>
    <span className={s.info__text}>3) Срок до 3, 6, а иногда и 12 месяцев.</span>

    <h3 className={s.info__subtitle}>Что будет при невыплате заемных средств?</h3>
    <span className={s.info__text}>
      В случае невозвращения в условленный срок суммы займа или суммы процентов за
      пользование заемными средствами кредитор вынуждено начислит штраф за просрочку
      платежа. Они предусмотрены на случай, например, если банковский перевод занял больше
      времени, чем обычно. Однако, в случае неполучения от Вас какой-либо реакции в
      течение продолжительного времени, будет начислен штраф за просрочку срока погашения.
      При несоблюдении Вами условий по погашению кредитов и займов, данные о Вас могут
      быть переданы в реестр должников или БКИ, что негативно может сказаться на Вашей
      кредитной истории и рейтинге кредитоспособности. Задолженность может быть передана
      коллекторскому агентству для взыскания долга. При допуске просрочки продление займа
      невозможно. Погашая задолженность в срок, Вы формируете хорошую кредитную историю,
      что повышает Ваш рейтинг кредитоспособности и шансы в дальнейшем получить кредит на
      более выгодных условиях.
    </span>

    <h3 className={s.info__subtitle}>Как погасить заём на карту?</h3>
    <span className={s.info__text}>
      Полученные в МФО заёмы можно погашать несколькими способами:
    </span>
    <span className={s.info__text}>
      1) С карты на которую были зачислены заемные деньги;
    </span>
    <span className={s.info__text}>2) Переводом по одной из систем;</span>
    <span className={s.info__text}>3) В терминалах;</span>
    <span className={s.info__text}>4) Через электронные кошельки;</span>
    <span className={s.info__text}>5) Банковским переводом.</span>

    <span className={s.info__text}>
      Возврат займа осуществляется одним платежом в день окончания кредитного соглашения
      Некоторые компании предлагают пролонгацию, но для этого следует оплатить проценты,
      которые уже начислены.
    </span>
  </>
);
