import React from 'react';

import { Button } from '../../../../shared/Button';
import { CardDataType } from '../../../../types';
import { checkImgSource, checkSumIfNone, checkTermIfNone } from '../../../../utils';
import s from './style/DesktopOrangeCard.module.scss';

export const DesktopOrangeCard: React.FC<CardDataType> = ({
  sum,
  age,
  term,
  percent,
  logo,
  advantage,
  affiliate_url,
  limit,
  cashback,
  delivery,
  period,
  bonus,
  name,
}) => (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
  <div
    onClick={() => {
      window.open(affiliate_url, '_blank');
    }}
    className={s.card}
  >
    <div className={s.header}>
      <div className={s.header__logo}>
        <img src={checkImgSource(logo, name)} alt="card-logo" />
      </div>

      <span className={s.header__blue}>
        <div className={s.advantage}>{advantage || 'Решение онлайн'}</div>
      </span>

      <Button>ПОЛУЧИТЬ</Button>
    </div>

    <div className={s.content}>
      <div className={s.container}>
        <div className={s.container__item}>
          <span className={s.item__top}>{sum ? 'Сумма' : 'Лимит'}</span>
          <span className={s.item__bottom}>{(sum && checkSumIfNone(sum)) || limit}</span>
        </div>
      </div>

      <div className={s.container}>
        <div className={s.container__item}>
          <span className={s.item__top}>{term ? 'Срок' : 'Льготный период'}</span>
          <span className={s.item__bottom}>
            {(term && checkTermIfNone(term)) || period}
          </span>
        </div>
      </div>

      <div className={s.container}>
        <div className={s.container__item}>
          <span className={s.item__top}>
            {percent ? 'Выгодный' : cashback ? 'Кешбек' : 'Бонус'}
          </span>
          <span className={s.item__bottom}>
            {(percent && 'Процент') || bonus || cashback}
          </span>
        </div>
      </div>

      <div className={s.container}>
        <div className={s.container__item}>
          <span className={s.item__top}>{age ? 'Возраст' : 'Доставка'}</span>
          <span className={s.item__bottom}>{age || delivery}</span>
        </div>
      </div>
    </div>
  </div>
);
