import React, { useContext } from 'react';
import { CardDataType } from '../../../types';
import s from '../style/Card.module.scss';
import { MediaContext } from '../../../context/MediaProvider';
import { DesktopBlueCard } from './DesktopBlueСard';
import { MobileBlueCard } from './MobileBlueCard';

export const CardBlue: React.FC<CardDataType> = props => {
  const tablet = useContext(MediaContext);

  return (
    <article className={s.container}>
      {tablet ? <DesktopBlueCard {...props} /> : <MobileBlueCard {...props} />}
    </article>
  );
};
