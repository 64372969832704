import { useEffect, useState } from 'react';

import { AxiosError } from 'axios';

import { CardApi } from '../api/card-service';
import { CardDataType } from '../types';
import { getShowCase } from '../themes';

export const useFetchingCardShort = () => {
  const [cardData, setCardData] = useState<CardDataType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    (async () => {
      const credit_type = window.location.pathname.slice(1);
      try {
        setIsLoading(true);
        const response = await CardApi.getCard({
          aff_sub2: '',
          credit_type: credit_type || '',
          showcase: getShowCase(credit_type),
          user_agent: '',
        });
        setCardData(response.data);
      } catch (err) {
        setError((err as AxiosError).message);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return { cardData, isLoading, error };
};
