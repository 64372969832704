import useDocumentState from '../../store/atoms/documentState/documentState';
import { useCallback, useMemo } from 'react';
import { documentPathType } from '../../store/atoms/documentState';

const DocumentModel = () => {
  const [state, setState] = useDocumentState();

  const openPdf = useCallback((pdfFile: documentPathType) => {
    setState({ pdfFile, opened: true });
  }, []);

  const closePdf = useCallback(() => {
    setState({ pdfFile: null, opened: false });
  }, []);

  const result = useMemo(() => ({ documentState: state, openPdf, closePdf }), [state]);

  return { ...result };
};

export default DocumentModel;
