import { useEffect } from 'react';
import { currentDomain, DomainDataStore } from '../../themes';
import TagManager from 'react-gtm-module';

export const useMetrics = () => {
  useEffect(() => {
    const gtmId = DomainDataStore.googleMetrics[currentDomain];
    if (gtmId) TagManager.initialize({ gtmId });
  }, []);
};
