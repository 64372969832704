import axios from 'axios';
import { getServerUrl } from './utils';

const SERVER_URL = getServerUrl();

export const instance = axios.create({
  withCredentials: true,
  headers: {
    'Content-Type': 'application-service/json',
    frm: window.location.search || 'null',
    fronturl: window.location.origin,
  },
  baseURL: SERVER_URL,
});
