import React from 'react';
import { CardDataType } from '../../../../types';
import s from './style/MobileOrangeCard.module.scss';
import { Button } from '../../../../shared/Button';
import { checkImgSource, checkSumIfNone, checkTermIfNone } from '../../../../utils';

export const MobileOrangeCard: React.FC<CardDataType> = ({
  name,
  sum,
  age,
  term,
  percent,
  logo,
  advantage,
  affiliate_url,
  limit,
  cashback,
  delivery,
  period,
  bonus,
}) => (
  <a href={affiliate_url} target="_blank" className={s.card} rel="noreferrer">
    <div className={s.header}>
      <div className={s.header__logo}>
        <img src={checkImgSource(logo, name)} alt="card-logo" />
      </div>
      <Button className={s.button} containerClassName={s.button__container}>
        ПОЛУЧИТЬ
      </Button>
    </div>
    <div className={s.advantage}>{advantage || 'Решение онлайн'}</div>
    <div className={s.content}>
      <div className={s.content__row}>
        <span>{(sum && checkSumIfNone(sum)) || limit}</span>
        <span>{(term && checkTermIfNone(term)) || period}</span>
      </div>
      <div className={s.content__row}>
        <span>{percent || bonus || cashback}</span>
        <span>{age || delivery}</span>
      </div>
    </div>
  </a>
);
