import telegram from '../../../../../assets/icons/messengers/telegram.webp';
import viber from '../../../../../assets/icons/messengers/viber.webp';
import vk from '../../../../../assets/icons/messengers/vk.webp';

type MessengersDataType = {
  icon: string;
  link: string;
};
export const messengersData: MessengersDataType[] = [
  {
    icon: vk,
    link: 'https://vk.me/sobank',
  },
  {
    icon: viber,
    link: 'viber://pa?chatURI=zzaem',
  },
  {
    icon: telegram,
    link: 'https://t.me/zzaem_bot',
  },
];
