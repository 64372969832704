import React from 'react';
import { CardDataType } from '../../../../types';
import s from './style/MobileBlueCard.module.scss';
import { Button } from '../../../../shared/Button';
import { checkImgSource, checkSumIfNone, checkTermIfNone } from '../../../../utils';

export const MobileBlueCard: React.FC<CardDataType> = ({
  logo,
  advantage,
  sum,
  term,
  percent,
  affiliate_url,
  name,
}) => (
  <a href={affiliate_url} target="_blank" className={s.card} rel="noreferrer">
    <div className={s.header}>
      <div className={s.header__logo}>
        <img src={checkImgSource(logo, name)} alt="card-logo" />
      </div>
      <Button className={s.button} containerClassName={s.button__container}>
        Получить деньги
      </Button>
    </div>
    <div className={s.advantage}>{advantage || 'Решение онлайн'}</div>
    <div className={s.content}>
      <div className={s.content__row}>
        <span>{sum && checkSumIfNone(sum)}</span>
        <span>{percent}</span>
        <span>{term && checkTermIfNone(term)}</span>
      </div>
    </div>
  </a>
);
