import React from 'react';

import s from '../App.module.scss';

import { Card } from './components/Card';
import { Header } from './components/Header';
import { useAutoRedirect } from './hook/useAutoRedirect';

export const Second: React.FC = () => {
  useAutoRedirect();
  return (
    <div className={s.layout}>
      <Header />
      <Card />
    </div>
  );
};
