import { atom, useRecoilState } from 'recoil';
import { initialDocumentState } from './index';

export const documentState = atom({
  key: 'document',
  default: initialDocumentState,
});

const useDocumentState = () => useRecoilState(documentState);

export default useDocumentState;
