import React, { CSSProperties, lazy, Suspense } from 'react';
import DocumentModel from '../../models/DocumentModel/DocumentModel';
import { Preloader } from '../../shared/Preloader';
import { DomainDataStore } from '../../themes';

const Document = lazy(() =>
  import('@ca-actual-projects/sobankui').then(({ PdfContainer }) => ({
    default: PdfContainer,
  })),
);
const DocumentContainer = () => {
  const { documentState, closePdf } = DocumentModel();
  const ownerInfo = DomainDataStore.footer.mircozaim;

  const ownerInfoProps = {
    inn: ownerInfo.ip_number,
    owner: ownerInfo.ip_owner,
    address: {
      factAddress: ownerInfo.address,
      registrationAddress: ownerInfo.address,
    },
    registrationNumber: ownerInfo.licenceNumber,
    ogrn: '12525151',
  };

  const containerStyle: CSSProperties = {
    paddingTop: 0,
  };

  return (
    <Suspense fallback={<Preloader />}>
      <Document
        theme="sobank"
        name="Центр подбора займов"
        origin={window.location.origin}
        closeHandler={closePdf}
        containerStyle={containerStyle}
        {...documentState}
        {...ownerInfoProps}
      />
    </Suspense>
  );
};

export default DocumentContainer;
