import React from 'react';

import 'styles/reset.scss';

import { BrowserRouter } from 'react-router-dom';

import { App } from 'pages/App';
import { MediaProvider } from './context/MediaProvider';
import { RecoilRoot } from 'recoil';
import { createRoot } from 'react-dom/client';

const rootElem = document.getElementById('root')!;

const root = createRoot(rootElem);

root.render(
  <MediaProvider>
    <RecoilRoot>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </RecoilRoot>
  </MediaProvider>,
);
