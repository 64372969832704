import React from 'react';
import com from '../../styles/common.module.scss';
import { currentDomain, DomainDataStore } from '../../themes';

export const Header = () => (
  <div className={com.title}>
    <h1 className={com.title__h1}>{DomainDataStore.header[currentDomain].title}</h1>
    <h2 className={com.title__h2}>{DomainDataStore.header[currentDomain].subtitle}</h2>
  </div>
);
