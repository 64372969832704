import React, { FC } from 'react';

import { ReactComponent as Arrow } from '../../../../assets/icons/card-arrow.svg';
import { Button } from '../../../../shared/Button';
import { CardDataType } from '../../../../types';
import { checkImgSource, checkSumIfNone, checkTermIfNone } from '../../../../utils';
import s from './style/DesktopBlueСard.module.scss';

export const DesktopBlueCard: FC<CardDataType> = ({
  logo,
  sum,
  term,
  // percent,
  affiliate_url,
  name,
}) => (
  <a href={affiliate_url} target="_blank" className={s.card} rel="noreferrer">
    <div className={s.card__logo}>
      <img src={checkImgSource(logo, name)} alt="logo" />
    </div>

    <div className={s.card__item}>
      <div className={s.card__body}>
        <p className={s.card__title}>Доступно</p>
        <p className={s.card__subtitle}>{sum && checkSumIfNone(sum)}</p>
      </div>
      <div className={s.card__icon}>
        <Arrow />
      </div>
    </div>

    {/* {percent && ( */}
    {/*  <div className={s.card__item}> */}
    {/*    <div className={s.card__body}> */}
    {/*      <p className={s.card__title}>Процент</p> */}
    {/*      <p className={s.card__subtitle}>{percent}</p> */}
    {/*    </div> */}
    {/*    <div className={s.card__icon}> */}
    {/*      <Arrow /> */}
    {/*    </div> */}
    {/*  </div> */}
    {/* )} */}

    <div className={s.card__item}>
      <div className={s.card__body}>
        <p className={s.card__title}>Срок</p>
        <p className={s.card__subtitle}>{term && checkTermIfNone(term)}</p>
      </div>
    </div>
    <Button icon>Получить деньги</Button>
  </a>
);
