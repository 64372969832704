import { CardDataType } from '../../../../types';
import smartCardLogo from '../../../../assets/logo/smartCardLogo.webp';

export const smartCardData: CardDataType = {
  advantage: 'Займы быстро и удобно',
  name: 'chat-bots',
  logo: smartCardLogo,
  sum: 'до 100000',
  term: 'до 365 дней',
  affiliate_url: `/second${window.location.search}`,
  age: '18-75',
};
