const urlConfig = {
  dengionline: 'https://dengionline.site',
  kreditzaim: 'https://1kreditzaim.com.ru',
  active_money: 'https://active-money.ru',
  credit_online: 'https://credit-online.com.ru',
  mircozaim: 'https://microzaim.org',
  zaem_system: 'https://zaemsystem.ru',
  fast_kred: 'https://fast-kred.ru',
};

export type DomainsName = keyof typeof urlConfig;

export const getCurrentDomainName = (): DomainsName => {
  const { origin } = window.location;

  let name: DomainsName = 'mircozaim';

  if (/localhost/i.test(origin)) {
    document.body.setAttribute('data-theme', name);

    return name;
  }

  for (const key in urlConfig) {
    const k: DomainsName = key as DomainsName;
    if (urlConfig[k].includes(origin)) {
      name = k;
    }
  }

  // if (name === 'mircozaim') document.title = 'Центр подбора займов';

  document.body.setAttribute('data-theme', name);

  return name;
};
