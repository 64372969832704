import { useEffect, useState } from 'react';

export const useTimer = () => {
  const [time, setTime] = useState(59);
  useEffect(() => {
    const timer = setInterval(() => {
      setTime(prevTime => (prevTime <= 1 ? 59 : prevTime - 1));
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);
  return time;
};
