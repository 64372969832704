import React from 'react';

import { Header } from '../../components/Header';
import { Cards } from './Cards';

export const Home = () => (
  <>
    <Header />
    <Cards />
  </>
);
