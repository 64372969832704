import React, { useCallback } from 'react';

import { FooterFields } from '../../../themes';
import { HelpInfoTypeFirst } from './HelpInfoTypeFirst';
import { HelpInfoTypeSecond } from './HelpInfoTypeSecond';

import s from '../style/HelpInfo.module.scss';
import DocumentModel from '../../../models/DocumentModel/DocumentModel';

type HelpInfoBodyPropsType = {
  footerData: FooterFields;
};

export const HelpInfoBody: React.FC<HelpInfoBodyPropsType> = ({ footerData }) => {
  const { openPdf } = DocumentModel();

  const openPoliticsHandler = useCallback(() => {
    openPdf('politics');
  }, []);

  const openOfferHandler = useCallback(() => {
    openPdf('offer');
  }, []);

  const openProcessingHandler = useCallback(() => {
    openPdf('processing');
  }, []);

  return (
    <div>
      {footerData.type === 'first' ? <HelpInfoTypeFirst /> : <HelpInfoTypeSecond />}
      <h3 className={s.info__subtitle}>ЛИЦЕНЗИИ ОРГАНИЗАЦИЙ-ПАРТНЁРОВ:</h3>
      <span className={s.info__text}>
        Лицензии организаций-партнеров: №651303552003006, №651303045003951,
        №2110177000478, №651303322004222, №651303045004102, №2120177002077,
        №651303532004088, №2120754001243, №651403140005467, №001503760007126,
        №1903475009492 , №2110177000037127018, №651303045003161 «Содействие банкам»,
        официальный сайт. Информация о процентных ставках по договорам банковского вклада
        с физическими лицами. Центр раскрытия корпоративной информации. Информация
        профессионального участника рынка ценных бумаг. Информация о лицах, под контролем
        либо значительным влиянием которых находятся Банки-партнёры. Лицензия на хранение
        и обработку персональных данных № 74-18-004739 Приказ № 212 от 23.11.2018. <br />{' '}
        Оставляя заявку, вы соглашаетесь на: <br />{' '}
        <span>
          <button onClick={openPoliticsHandler} type="button" className={s.underline}>
            обработку персональных данных
          </button>
          {', '}
          получение информации, получение доступа к кредитной истории, использование
          аналога собственноручной подписи,{' '}
        </span>
        <span>
          <button onClick={openOfferHandler} type="button" className={s.underline}>
            оферту
          </button>
        </span>
        <span>
          ,{' '}
          <button onClick={openPoliticsHandler} type="button" className={s.underline}>
            политика относительно обработки персональных данных
          </button>
        </span>
        ,{' '}
        <button onClick={openProcessingHandler} type="button" className={s.underline}>
          форма согласия на обработку персональных данных
        </button>
        .
      </span>
      <h3 className={s.info__subtitle}>ТРЕБОВАНИЯ К ЗАЕМЩИКАМ</h3>
      <span className={s.info__text}>1. Гражданство РФ</span>
      <span className={s.info__text}>2. Возраст от 18 лет;</span>
      <span className={s.info__text}>3. Постоянный доход;</span>
      <span className={s.info__text}>4. Финансовая ответственность.</span>
      <span className={s.info__text}>
        Сайт НЕ является представительством МФО или банком, не осуществляет выдачу займов
        и кредитов. Мы используем файлы cookie для того, чтобы предоставить пользователям
        больше возможностей при посещении сайта . Услуги бесплатные на сайте оказывает ИП{' '}
        {footerData.ip_owner} {footerData.ip_number} и не несет ответственности за
        заключенные впоследствии клиентами и партнерами договоров кредитования.
      </span>
      <span className={`${s.info__text} ${s.pb__clear}`}>
        Email: support@{footerData.email}
      </span>
      <span className={s.info__text}>{footerData.phone}</span>
      <span className={s.info__text}>{footerData.address}</span>
      <span className={s.info__text}>Согласие на обработку персональных данных.</span>
      {footerData.licenceNumber && (
        <>
          <span className={s.info__text}>
            Оператор персональных данных с Регистрационным номером{' '}
            {footerData.licenceNumber}
          </span>

          {footerData.orderNumber && (
            <span className={s.info__text}>
              Лицензия на хранение и обработку персональных данных №{' '}
              {footerData.licenceNumber} Приказ № {footerData.orderNumber}
            </span>
          )}
        </>
      )}
      <span className={s.info__text}>
        В соответствии с требованиями Федерального закона №152-ФЗ «О персональных данных»
        (далее просто Закон), я ____ ________года рождения, абонентский номер _______
        (далее Пользователь) предоставляю добровольное согласие ИП&nbsp;
        {footerData.ip_owner} ИНН {footerData.ip_number} (далее указывается просто
        ОПЕРАТОР), на выполнение обработку предоставленных мной персональных сведений,
        включающих в себя:
      </span>
      <ul className={s.list}>
        <li>фамилия, имя и отчество;</li>
        <li>адрес регистрации и фактического проживания;</li>
        <li>место рождения; дата, месяц и год рождения;</li>
        <li>гражданство;</li>
        <li>данные документа, удостоверяющего личность (далее – Паспорт);</li>
        <li>номер мобильного телефона;</li>
        <li>номер домашнего и(или) рабочего телефона (в случае предоставления);</li>
        <li>адрес электронной почты;</li>
        <li>
          персональные данные, содержащиеся в открытых и (или) общедоступных источниках, в
          том числе, в сети Интернет;
        </li>
        <li>
          иная информация, содержащаяся в документах, предоставленных Пользователем
          ОПЕРАТОРУ (далее – Персональные данные);
        </li>
      </ul>
      <span className={s.info__text}>
        и в том числе данные : идентификационные (для установления моей личности),
        контактные (для осуществления взаимодействия и информирования), платежные, данные
        о оборудовании (устройстве)для осуществления моих действий в сети интернет, данные
        о моем трудоустройстве, данные о моем правовом статусе, финансовые и имущественные
        данные, то есть все мои данные необходимые Оператору для предоставления мне услуг,
        а также данные, указанные и измененные мною в анкете (информационной системе
        ОПЕРАТОРА) и иные данные.
      </span>
      <span className={s.info__text}>
        Предоставленная мной информация может быть использована для:
      </span>
      <ul className={`${s.list} ${s.list__num}`}>
        <li>предложения продуктов и услуг ОПЕРАТОРА, а также партнеров ОПЕРАТОРА;</li>
        <li>
          предоставления скидок и льготных условий предоставления займов; повышение
          удобства использования продуктов и услуг ОПЕРАТОРА,
        </li>
        <li>
          создание новых продуктов и услуг ОПЕРАТОРА; сбор, обработка и представление
          статистических данных, больших данных и других исследований;
        </li>
      </ul>
      <span className={s.info__text}>
        также согласие, предоставленное мной, распространяется на следующие действия,
        которые будут совершены с моими персональными данными: сбор, накопление, хранение,
        передачу (предоставление, доступ, распространение), изменение, обновление,
        извлечение, использование, обезличивание, блокирование и уничтожение, (в том числе
        смешанную, с использованием средств и без использования средств автоматизации),
        опубликование, редактирование, копирование, передачу данных в государственные
        учреждения в случае необходимости совершения юридических действий, а также иные
        действия, не противоречащие закону Российской Федерации.
      </span>
      <span className={s.info__text}>
        Также, мое согласие включает в себя право ОПЕРАТОРА передавать мои – Пользователя
        персональные данные, в том числе данные находящиеся в открытых источниках (любые
        мои персональные данные), сделанные общедоступными мною – Пользователем
        посредством сети интернет, а также содержащиеся в общедоступных источниках ( фото,
        семейное положение, дата рождения, город- населенный пункт, родственные связи,
        адрес проживания, абонентский номер и иные данные) для их обработки ОПЕРАТОРОМ и
        также юридическими лицами и иными лицами, с которыми ОПЕРАТОР заключил договора.
      </span>
      <span className={s.info__text}>
        ОПЕРАТОР будет осуществлять обработку Персональных данных с использованием средств
        автоматизации, а также без использования таких средств. Согласие предоставляется
        на любые операции или сумму операций, для совершения которых необходимо
        использовать средства автоматизации или для выполнения операций без применения
        данных средств. Операции включают в себя: сбор, запись, систематизацию информации,
        накопление, хранение, уточнение, извлечение, передачу, блокирование,
        обезличивание, удаление персональных данных в соответствии со ст. 3, ст.9, ст. 15
        Закона. Выполняется в целях, которые связаны с оказанием услуг (выполнением
        соглашений с Пользователем), идентификации Пользователя как стороны в рамках
        соглашений и договоров, предоставления Пользователю персонализированных Сервисов
        для осуществления связи с пользователем, в том числе для осуществления таких
        операций, как направления уведомлений (запросов и другой информации), которая
        касается применения Сервисов оказания услуг, также для оперативной обработки
        заявок от пользователя, существенного улучшения качества Сервисов, удобства их
        применения, разработки новых Сервисов и услуг, выполнения статистических или иных
        исследований на основе обезличенных данных и в других целях, которые были заранее
        определены в Уставе Оператора.
      </span>
      <span className={s.info__text}>
        Я выражаю свое согласие на то, что Оператор под свою исключительную
        ответственность вправе отдать поручение на выполнение обработки персональных
        сведений любому 3-ему лицу руководствуясь своим усмотрением, но при этом должны
        быть соблюдены условия требований ст. 6 Закона, в том числе лицо должно сохранять
        предоставленные ему сведения конфиденциальными и выполнять защиту полученных
        данных. Мои персональные данные могут быть переданы 3-ей стороне и получение от
        3-ей стороны персональных данных может выполняться Операторами персональных данных
        с момента подписания данного согласия.
      </span>
      <span className={s.info__text}>Целями обработки персональных данных являются:</span>
      <ul className={`${s.list} ${s.list__pb}`}>
        <li>
          получение мною предложений о финансовых услугах в течение срока действия
          Согласия от Операторов, для чего ОПЕРАТОР будет хранить и передавать мои
          персональные данные Операторам;
        </li>
        <li>
          контакты со мной как с потенциальным потребителем услуг Операторов для
          продвижения услуг Операторов;
        </li>
        <li>получение мною рекламных рассылок по сетям связи;</li>
        <li>оценка моей кредитоспособности;</li>
        <li>использование моих открытых в интернете данных;</li>
        <li>контакты со мной для продвижения услуг ОПЕРАТОРА;</li>
        <li>
          получение мною информации от ОПЕРАТОРА о перечне Операторов, которые
          обрабатывают мои данные с использованием информационной системы ОПЕРАТОРА;
        </li>
        <li>
          увеличение качества предоставляемых мне предложений от Операторов, для чего
          ОПЕРАТОР может организовывать исследование моих персональных данных, в том числе
          статистических, а также исследование действий и отчётов моего оборудования
        </li>
      </ul>
      <span className={s.info__text}>
        Настоящим, я извещен, что согласно п. 5. ст. 21 Закона, что данное согласие может
        быть отозвано мной посредством направления соответствующего уведомления о его
        отзыве, которое должно быть направлено в адрес Оператора на электронную почту
        support@{footerData.email}, или по почте заказным письмом с уведомлением о
        вручении. Срок действия моего согласия составляет десять лет. Я понимаю, что отзыв
        Согласия не означает, что мои персональные данные не будут использоваться иными
        Операторами кроме ОПЕРАТОРА, которым к моменту такого отзыва были переданы мои
        персональные данные. Я понимаю, что после такого отзыва представители ОПЕРАТОРА
        могут продолжать общаться со мной по поводу моей задолженности и иных
        неисполненных перед ОПЕРАТОРОМ обязательств, а также что ОПЕРАТОР вправе
        продолжить обработку моих персональных данных и без моего согласия при наличии
        оснований, указанных в пунктах 2 - 11 части 1 статьи 6, части 2 статьи 10 и части
        2 статьи 11 Федерального закона от 27.07.2006 № 152-ФЗ &quot;О персональных
        данных&quot;.
      </span>
      <span className={s.info__text}>
        Настоящим я также выражаю согласие с тем, что регистрация на сайте Оператора и/или
        оформление Заявки на услуги Оператора с учетом предварительного ознакомления с
        данным текстом является в соответствии с п. 1 ст. 9. Закона достаточной формой
        согласия на осуществление обработки моих персональных данных. Рассматриваемая
        форма согласия позволяет осуществить подтверждение факта получения моего согласия.
        Я соглашаюсь, что данная форма согласия полностью подтверждает факт согласия и при
        этом не требуется письменная форма или иные доказательства моего свободного
        волеизъявления Оператору не потребуются. Моё согласие является осмысленным и
        конкретным.
      </span>
      <span className={s.info__text}>
        Дача мною Согласия не препятствует мне давать ОПЕРАТОРУ иные согласия на обработку
        моих персональных данных. В случае, если в отношении одних и тех же моих
        персональных данных мною дано ОПЕРАТОРУ несколько согласий, действует то, которое
        дано позднее, и только в той части, в которой оно отличается от Согласия. Дача
        мною иных согласий на обработку моих персональных данных не отменяет Согласия и
        изменяет его только в той части, в которой это будет прямо указано или очевидно
        вытекает из текстов согласий.
      </span>
      <span className={s.info__text}>
        Я Пользователь подтверждаю и оформляю формирование Согласия на носителе.
      </span>
      <span className={s.info__text}>
        Я Пользователь могу предоставлять свои данные и изменять их предоставленный объём
        через интернет, в том числе, не ограничиваясь, через анкеты, мобильные приложения,
        авторизацию в социальных сетях, по телефону, путём кодов в смс-сообщениях, через
        единую систему идентификации и аутентификации (ЕСИА). Я могу предоставлять свои
        данные и изменять их предоставленный объём через обращения к ОПЕРАТОРУ на бумажном
        носителе, с заверением моей подписи представителем ОПЕРАТОРА и/или нотариусом.
        Формирование Согласия в объективной форме (на бумажном носителе или в электронном
        виде) осуществляется путём объединения автоматизированным способом предоставленных
        мною персональных данных и формы настоящего Согласия.
      </span>
      <span className={s.info__text}>
        Я Пользователь подтверждаю, что действую от своего имени и не являюсь
        представителем, лицом, которое представляет интересы Пользователя и действует от
        имени Пользователя на основании доверенности, закона, либо акта уполномоченного на
        то государственного органа или органа местного управления, а также об отсутствии
        принадлежности к иностранным публичным должностным лицам или к публичным
        должностным лицам.
      </span>
      <span className={s.info__text}>
        Сервис осуществляет подбор микрозаймов, между клиентом и кредитным учреждением, а
        также некредитными финансовыми организациями, которые занимаются выдачей займов.
        Работаем со всеми клиентами — не важно, какая у вас кредитная история и имеются ли
        текущие просрочки, что существенно расширяет возможности человека по решению
        собственных проблем.
      </span>
      <span className={`${s.info__text} ${s.pb__clear}`}>Наши преимущества:</span>
      <ul className={`${s.list} ${s.list__pb}`}>
        <li>Сайт доступен с любого устройства 24/7</li>
        <li>Высокий процент одобрений</li>
        <li>Оформление заявки занимает не более 5 минут</li>
        <li>Полная конфиденциальность и безопасность</li>
        <li>Заполнение заявок по телефону</li>
        <li>Смс-информирование</li>
        <li>Проверка кредитной истории для повышения процента одобрения</li>
      </ul>
      <span className={s.info__text}>
        Это отличный выход из сложной ситуации, если вам срочно требуются деньги для
        совершения крупной покупки, проведения праздника, отдыха с семьей или не хватает
        средств на повседневные расходы до зарплаты. Но далеко не всегда у друзей или
        родственников есть необходимая сумма, а получение кредита требует сил и времени.
        Специалистами был разработан данный сервис, который позволяет в кратчайший срок
        подобрать выгодное предложение для решения финансовых сложностей. От вас требуется
        только паспортные данные и карта, оформленная на ваше имя. Не хватает финансов? Не
        проблема, просто воспользуйтесь нашим сервисом.
      </span>
      <span className={s.info__text}>
        Заём выдается в российских рублях гражданам Российской Федерации, на банковский
        счет, на карту или наличными. Минимальная сумма займа: 1 000 рублей. Максимальная
        сумма займа: 100 000 рублей. Процентная ставка минимальная, срок займа от 1 до 365
        дней включительно.
      </span>
      <span className={s.info__text}>
        * У пользователей сервиса есть возможность получить заём с минимальной процентной
        ставкой. Подробности при выборе персонального предложения. Итоговая сумма займа
        может быть изменена по результатам первичной проверки анкеты.
      </span>
      <span className={s.info__text}>
        Информация, размещенная на данном сайте, носит исключительно справочный характер и
        собрана из различных открытых источников информации в соответствии с
        законодательством РФ. Владелец сайта не несет ответственности за вред чести,
        достоинству и деловой репутации, за ущерб связанный с упущенной выгодой в
        результате использования Сайта.
      </span>
      <span className={s.info__text}>
        Совершая любые действия на сайте, вы даете свое&nbsp;
        <button onClick={openProcessingHandler} type="button" className={s.underline}>
          Согласие на обработку персональных данных и Согласие на получение рекламных
          материалов.
        </button>
      </span>
      <span className={s.info__text}>
        Сайт не является финансовым учреждением, банком или кредитором, не несёт и не
        может нести ответственность за любые условия или заключенные договоры кредитования
        с третьими сторонами.
      </span>
      <span className={s.info__text}>
        Сайт предназначен для дееспособных лиц старше 18 лет. Сайт не хранит данные
        банковских карт Клиентов. Сайт использует куки для предоставления услуг. Условия
        хранения или доступа к куки Вы можете настроить в своем браузере.
      </span>
    </div>
  );
};
