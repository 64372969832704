export const getServerUrl = () => {
  const currentDomain = window.location.origin;
  const developerBackend = 'https://develop.onbank.online';
  const prodCentofinanceBackend = 'https://back.microzaim.org';
  const testBackend = 'https://test-back.onbank.online';
  let serverUrl;

  if (currentDomain.includes('localhost') || currentDomain.includes('dev-front')) {
    serverUrl = developerBackend;
  } else if (currentDomain.includes('test-front.onbank.online')) {
    serverUrl = testBackend;
  } else {
    serverUrl = prodCentofinanceBackend;
  }

  return serverUrl;
};
