import React, { Fragment } from 'react';

import { CardOrange } from '../../../components/CardsItems';
import com from '../../../styles/common.module.scss';
import { CardDataType } from '../../../types';
import s from '../style/Home.module.scss';

import { useFetchingCardShort } from '../../../hook/useFetchingCardShort';

export const Cards = () => {
  const resultCard = (props: CardDataType) => <CardOrange {...props} />;

  const { cardData } = useFetchingCardShort();
  return (
    <div className={`${com.container} ${com.cardWrapper}`}>
      {cardData.map(card => (
        <Fragment key={card.affiliate_url}>{resultCard(card)}</Fragment>
      ))}
      <div className={s.text}>
        Настоящий сайт является составным произведением, которое представляет собой в том
        числе каталог товарных знаков (знаков обслуживания), опубликованных в открытых
        реестрах ФИПС (Роспатент) и сопровождающихся подобранным каталогом финансовых
        услуг. Исключительное право на товарные знаки (знаки обслуживания), представленные
        в вышеуказанном каталоге, принадлежат их правообладателям и охраняются в
        соответствии с выданными ФИПС (Роспатент) правоустанавливающими документами для
        индивидуализации товаров, работ или услуг их непосредственных правообладателей.
      </div>
    </div>
  );
};
