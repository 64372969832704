import { useHistory } from 'react-router-dom';

export const useHistoryWithUTM = () => {
  const history = useHistory();

  const methods = {
    location: history.location,
    reload() {
      window.location.reload();
    },
    checkProtocol(protocol: string) {
      return window.location.protocol === protocol;
    },
    toHttps() {
      if (this.checkProtocol('http:') && !window.location.origin.includes('localhost')) {
        window.location.href = window.location.href.replace('http:', 'https:');
      }
    },
  };

  return methods;
};
