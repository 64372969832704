import React from 'react';
import com from '../../../../styles/common.module.scss';

import s from './style/Header.module.scss';

import { ReactComponent as Logo } from '../../../../assets/icons/secondPage/secondPageIcon.svg';

export const Header: React.FC = () => (
  <div className={com.container}>
    <div className={s.header}>
      <div className={s.header__logo}>
        <Logo className={s.logo__icon} />
        <span className={s.logo__text}>Экспресс заём</span>
      </div>
    </div>
  </div>
);
