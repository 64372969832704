import React from 'react';
import s from '../style/HelpInfo.module.scss';

export const HelpInfoTypeFirst = () => (
  <>
    <span className={s.info__text}>
      Заёмы предоставляются на сумму от 1000 до 100 000 руб на срок от 61 дня до 1
      года.При получении первого займа в размере 31 000 рублей на срок 91 день сумма к
      оплате составит 31 000 рублей. При получении повторного займа в размере 31 000 руб
      на срок 52 недели переплата за весь срок составит 37 226 рублей. Итого вы
      возвращаете 68 226 рублей.
    </span>
    <h3 className={s.info__subtitle}>ПОСЛЕДСТВИЯ НЕВЫПЛАТЫ</h3>
    <span className={s.info__text}>
      Большинство кредиторов без проблем дают до 3-х дней отсрочки по платежу – этого
      времени достаточно для устранения банковских задержек или разрешения личных проблем.
      В случае более длительной задержки штрафа избежать, скорее всего, не получится. В
      среднем, штраф составляет 0,1% от изначальной суммы кредита, 0,3% от суммы всех
      задолженностей для потребительских кредитов и кредитных карт. В случае длительной
      просрочки и неуплаты штрафа, ваш долг может отойти коллекторам.
    </span>
    <h3 className={s.info__subtitle}>ЧТО БУДЕТ ПРИ НЕВЫПЛАТЕ ЗАЕМНЫХ СРЕДСТВ</h3>
    <span className={s.info__text}>
      В случае невозвращения в условленный срок суммы займа или суммы процентов за
      пользование заемными средствами кредитор вынуждено начислит штраф за просрочку
      платежа. Они предусмотрены на случай, например, если банковский перевод занял больше
      времени, чем обычно. Однако, в случае неполучения от Вас какой-либо реакции в
      течение продолжительного времени, будет начислен штраф за просрочку срока погашения
      размером в среднем 0,10% от первоначальной суммы для займов, 0,03% от суммы
      задолженности в среднем для потребительских кредитов и кредитных карт. При
      несоблюдении Вами условий по погашению кредитов и займов, данные о Вас могут быть
      переданы в реестр должников или БКИ, что негативно может сказаться на Вашей
      кредитной истории и рейтинге кредитоспособности. Задолженность может быть передана
      коллекторскому агентству для взыскания долга. При допуске просрочки продление займа
      невозможно. Погашая задолженность в срок, Вы формируете хорошую кредитную историю,
      что повышает Ваш рейтинг кредитоспособности и шансы в дальнейшем получить кредит на
      более выгодных условиях.
    </span>
  </>
);
