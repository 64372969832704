import { AxiosResponse } from 'axios';

import { CardDataType } from '../../types';
import { instance } from '../api-config';

type CardPayloadType = {
  aff_sub2: string;
  user_agent?: string;
  showcase?: number;
  credit_type?: string;
};

export class CardApi {
  static getCard(data: CardPayloadType): Promise<AxiosResponse<CardDataType[]>> {
    return instance.get('/api/showcase/get_leads_offer_list', {
      params: {
        aff_sub2: data.aff_sub2,
        user_agent: data.user_agent,
        showcase: data.showcase,
        credit_type: data.credit_type,
      },
    });
  }
}
