import React from 'react';

import { Cards } from './Cards';
import { CustomHeader } from '../../components/CustomHeader';

export const Short = () => (
  <>
    <CustomHeader />
    <Cards />
  </>
);
