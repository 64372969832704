import React from 'react';

import { ReactComponent as ArrowIcon } from '../../assets/icons/btn-icon.svg';

import s from './style/Button.module.scss';

import { ButtonPropsType } from 'shared/Button/type';
import { currentDomain } from '../../themes';

export const Button: React.FC<ButtonPropsType> = React.memo(
  ({ className, children, containerClassName, icon }) => {
    const finalClassName = ` ${s.button} ${s[currentDomain || '']} ${className || ''}`;

    return (
      <div className={containerClassName}>
        <span className={finalClassName}>
          {children}
          {icon && <ArrowIcon />}
        </span>
      </div>
    );
  },
);
