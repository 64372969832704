import { Nullable } from '../../../types';

type documentPathType = 'politics' | 'offer' | 'processing';

type DocumentStateType = {
  pdfFile: Nullable<documentPathType>;
  opened: boolean;
};

const initialDocumentState: DocumentStateType = { pdfFile: null, opened: false };

export { DocumentStateType, documentPathType, initialDocumentState };
