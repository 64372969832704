import React from 'react';
import com from '../../styles/common.module.scss';

export const CustomHeader = () => (
  <div className={com.title}>
    <h1 className={com.title__h1}>ВАМ ПРЕДВАРИТЕЛЬНО ОДОБРЕНЫ СЛЕДУЮЩИЕ ПРЕДЛОЖЕНИЯ</h1>
    <h2 className={com.title__h2}>
      Для гарантированного получения карты рекомендуем отправлять заявку в несколько
      банков
    </h2>
  </div>
);
