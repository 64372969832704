import { getServerUrl } from '../api/utils';

const FIRST_MATCH = 1;
const TERM_LENGTH = 3;
const TERM_SL_MIN = 1;
const TERM_SL_MAX = 2;

export function getUrlSearchParams(p: string) {
  const match = RegExp(`[?&]${p}=([^&]*)`).exec(window.location.search);
  return match && decodeURIComponent(match[FIRST_MATCH].replace(/\+/g, ' '));
}

export const checkSumIfNone = (sum: string) => {
  if (sum.includes('None')) {
    return 'до 30000';
  }
  return `${sum}`;
};

export const checkTermIfNone = (term: string) => {
  if (term.includes('None')) {
    return 'до 30 дней';
  }
  const termLen = term.split(' ').length;
  const isNumber = !!+term.split(' ').slice(TERM_SL_MIN, TERM_SL_MAX);
  if (termLen < TERM_LENGTH && isNumber) {
    return `${term} дней`;
  }
  return term;
};

export const smartVetrinaRedirect = (replace: boolean, first: boolean) => {
  const url = `${first ? 'https://microzaim.org/' : 'https://dengionline.site/'}${
    window.location.search
  }`;
  if (replace) {
    window.location.replace(url);
  } else {
    window.location.assign(url);
  }
};

export const checkImgSource = (href: string, name: string) => {
  if (!href.includes('https://') && name !== 'chat-bots') {
    return `${getServerUrl()}/${href}`;
  }
  return href;
};
