import React from 'react';

import com from '../../../../styles/common.module.scss';
import { smartVetrinaRedirect } from '../../../../utils';

import { messengersData } from './data';
import { useTimer } from './hook/useTimer';
import s from './style/Card.module.scss';

export const Card: React.FC = () => {
  const time = useTimer();
  const facilitiesText = 'Льготные условия\nистекают через';

  function messengerClickHandler(link: string) {
    window.open(link, '_blank');
    smartVetrinaRedirect(true, false);
  }

  function skipHandleClick() {
    smartVetrinaRedirect(true, false);
  }

  return (
    <div className={com.container}>
      <div className={s.card}>
        <div className={s.facilities}>
          <p className={s.facilities__text}>{facilitiesText}</p>
          <div className={s.facilities__timeCounter}>
            <span>14</span>
            <span>{time}</span>
          </div>
        </div>
        <div className={s.rangeContainer}>
          <div className={s.rangeContainer__item}>
            <span className={s.rangeContainer__text}>
              Вероятность одобрения заявки 95%
            </span>
          </div>
        </div>
        <h3 className={s.textBlock}>
          Выберите удобный способ связи, чтобы повысить верятность одобрения займа:
        </h3>
        <h4>Нажмите на иконку мессенджера</h4>
        <div className={s.messengers}>
          {messengersData.map(item => (
            <button
              key={item.link}
              className={s.messengers__item}
              type="button"
              onClick={() => messengerClickHandler(item.link)}
            >
              <img src={item.icon} alt="Messenger Icon" />
            </button>
          ))}
        </div>

        <button
          className={s.card__skip}
          onClick={() => {
            skipHandleClick();
          }}
          type="button"
        >
          <h4 className={s.card__skip}>Пропустить</h4>
        </button>
      </div>
    </div>
  );
};
