import React, { useContext } from 'react';
import { CardDataType } from '../../../types';
import { MediaContext } from '../../../context/MediaProvider';
import { DesktopOrangeCard } from './DesktopOrangeСard/DesktopOrangeCard';
import { MobileOrangeCard } from './MobileOrangeCard/MobileOrangeCard';
import s from '../style/Card.module.scss';

export const CardOrange: React.FC<CardDataType> = props => {
  const tablet = useContext(MediaContext);
  return (
    <article className={s.container}>
      {tablet ? <DesktopOrangeCard {...props} /> : <MobileOrangeCard {...props} />}
    </article>
  );
};
